<template>
  <ValidationObserver
    ref="form"
    v-slot="{ passes }"
    tag="section"
    class="min-h-screen pt-3 bg-gray-100"
  >
    <van-cell-group
      title="基本信息"
      class="mt-2"
    >
      <ValidationProvider
        v-slot="{ errors }"
        vid="category"
        name="类别"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.category"
          required
          readonly
          clearable
          placeholder="请选择您的意见/建议类别"
          autocomplete="off"
          label="类别"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showCategoryPicker = true"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="title"
        name="标题"
        mode="lazy"
        rules="required|min:2|max:50"
        slim
      >
        <van-field
          v-model="issue.title"
          required
          clearable
          placeholder="请输入您的意见/建议标题"
          autocomplete="off"
          label="标题"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="name"
        name="姓名"
        mode="lazy"
        rules="min:2|max:20"
        slim
      >
        <van-field
          v-model="issue.name"
          clearable
          placeholder="请输入您的姓名"
          label="姓名"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="mobile"
        name="手机号码"
        mode="lazy"
        rules="required|phone"
        slim
      >
        <van-field
          v-model="issue.mobile"
          required
          clearable
          placeholder="请输入您的手机号码以便联系"
          autocomplete="off"
          type="tel"
          label="手机号码"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        vid="unit"
        name="楼号及单元"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.unit"
          readonly
          clearable
          required
          label="楼号及单元"
          placeholder="请选择楼号及单元"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showUnitPicker = true"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        vid="room"
        name="房号"
        mode="lazy"
        rules="min:2|max:50"
        slim
      >
        <van-field
          v-model="issue.room"
          clearable
          placeholder="请输入您的房号，例如：901"
          autocomplete="off"
          label="房号"
          :error="errors.length > 0"
          :error-message="errors[0]"
        />
      </ValidationProvider>
      <ValidationProvider
        v-if="issue.category === '物业管理'"
        v-slot="{ errors }"
        vid="complaintAt"
        name="投诉日期"
        mode="lazy"
        rules="required"
        slim
      >
        <van-field
          v-model="issue.complaintAt"
          readonly
          clickable
          clearable
          required
          placeholder="请输入您首次向物业投诉的日期"
          autocomplete="off"
          label="投诉日期"
          :right-icon="issue.complaintAt ? 'close' : ''"
          :error="errors.length > 0"
          :error-message="errors[0]"
          @click="showComplaintPicker = true"
          @click-right-icon.prevent.stop="issue.complaintAt = null"
        />
      </ValidationProvider>
      <van-field
        v-model="issue.content"
        clearable
        autosize
        rows="2"
        type="textarea"
        placeholder="请输入您的意见和建议"
        autocomplete="off"
        label="内容"
        maxlength="1000"
        show-word-limit
      />
    </van-cell-group>

    <van-cell-group
      title="上传照片"
      class="px-4 py-2 mt-2"
    >
      <van-uploader
        v-model="issue.attachments"
        multiple
        :max-count="9"
        :before-read="beforeRead"
      />
      <div class="text-xs text-gray-300">
        最多上传 9 张照片
      </div>
    </van-cell-group>

    <div class="w-full px-4 mt-6">
      <van-button
        type="danger"
        block
        round
        :loading="processing"
        loading-text="发送中..."
        @click="passes(onSubmit)"
      >
        立即发送
      </van-button>
    </div>
    <van-popup
      v-model="showUnitPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="units"
        @cancel="showUnitPicker = false"
        @confirm="onUnitConfirm"
      />
    </van-popup>
    <van-popup
      v-model="showCategoryPicker"
      round
      position="bottom"
      :safe-area-inset-bottom="true"
    >
      <van-picker
        show-toolbar
        :columns="issueCategories"
        @cancel="showCategoryPicker = false"
        @confirm="onCategoryConfirm"
      />
    </van-popup>
    <van-calendar
      v-model="showComplaintPicker"
      first-day-of-week="1"
      type="single"
      :min-date="$dayjs().subtract(6, 'month').toDate()"
      :max-date="new Date()"
      @confirm="onComplaintConfirm"
    />
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import images from '@/mixins/uploader/images'
import share from '@/mixins/share'
export default {
  name: 'PublicIssuesCreate',
  mixins: [images, share],
  data() {
    return {
      loading: true,
      processing: false,
      showUnitPicker: false,
      showCategoryPicker: false,
      showComplaintPicker: false,
      issue: {
        title: null,
        name: null,
        mobile: null,
        unit: null,
        room: null,
        content: null,
        complaintAt: null,
        attachments: []
      }
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapGetters('Common/Credential', ['userInfo']),
    ...mapState('Common/Config', ['units', 'issueCategories'])
  },
  mounted() {
    this.initialize()
    this.disableShareMenu()
  },
  methods: {
    initialize() {
      if (this.userInfo?.status && this.userInfo.status !== '未认证') {
        this.issue.name = this.userInfo.name
        this.issue.mobile = this.userInfo.mobile
        this.issue.unit = this.userInfo.unit
        this.issue.room = this.userInfo.room
      }
    },
    onUnitConfirm(values) {
      this.issue.unit = values[0] == '无' ? null : values.join('-')
      this.showUnitPicker = false
    },
    onCategoryConfirm(value) {
      this.issue.category = value
      this.showCategoryPicker = false
      if (value === '物业管理') {
        this.$dialog
          .confirm({
            title: '温馨提醒',
            message:
              '关于物业管理的相关问题您可以直接向物业（电话：82230051）进行投诉，如您已向物业投诉且未能得到妥善解决，请您点击“我已投诉”按钮继续填写表单。',
            theme: 'round-button',
            showCancelButton: true,
            cancelButtonText: '拨打物业电话',
            confirmButtonText: '我已投诉'
          })
          .then(() => {
          })
          .catch(() => {
            window.open('tel:82235151')
          })
      }
    },
    onComplaintConfirm(value) {
      this.issue.complaintAt = this.$dayjs(value).format('YYYY-MM-DD')
      this.showComplaintPicker = false
    },
    onSubmit() {
      this.processing = true
      const data = {
        $url: 'subscriberPost',
        userInfo: this.userInfo,
        issue: this.issue
      }
      // eslint-disable-next-line no-console
      console.warn('onSubmit', JSON.stringify(data))
      this.tcb
        .callFunction({
          name: 'issues',
          data
        })
        .then(res => {
          // eslint-disable-next-line no-console
          console.log(res)
          let authenticated = ['已认证', '待审核'].includes(
            this.userInfo?.status
          )
          this.$dialog
            .confirm({
              title: '发送成功',
              message: authenticated
                ? '您的意见/建议已成功发送，感谢您的宝贵意见和建议！'
                : '您尚未进行业主认证，认证后填写意见建议会更方便哦。是否立即进行业主认证？',
              theme: 'round-button',
              showCancelButton: authenticated ? false : true,
              cancelButtonText: '返回列表',
              confirmButtonText: authenticated ? '返回列表' : '业主认证'
            })
            .then(() => {
              if (authenticated) {
                this.$router.replace({
                  name: 'public.issues'
                })
              } else {
                this.$router.push({
                  name: 'public.authentication'
                })
              }
            })
            .catch(() => {
              this.$router.replace({
                name: 'public.issues'
              })
            })
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          this.$notify({
            type: 'danger',
            message: '发送失败，由于微信服务异常，暂时无法发送'
          })
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
